
.grey-color{
    --tw-text-opacity: 1;
    color: grey;
}
.dashed-line{
    margin-top: 1rem;
    border-bottom-width: 1px;
    border-top-width: 0;
    border-style: dashed;
border-color: b1b5b9;

}

@media only screen and (max-width: 500px) {
  
    .price-section{
        padding: 0px;
       flex-direction: column;
    }
    .detail-btn{
        margin-top: 1rem;
        width: 100%;
    }
    .order-image{
        width: 100% !important;
    }
  }
