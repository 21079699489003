.footermain{
  background-color: #0d0f0f;
  padding: 30px;
  font-family: 'Montserrat', sans-serif;
}
.footertxt{
margin: 20px;
color: white;
font-size: 14px;
}
.lnktxt{
  text-decoration: none;
  color: white;
  font-size: 15px;
  top: 0;
  transition: 0.5s;
}
.lnktxt:hover{
  color: white;
  
}
.socialicn{
 height: 35px;
 width: 35px;
 background-color: #000000;
 padding: 5px;
 font-size: 16px;
 border-radius: 50%;
 text-align: center;
 margin-top: 10px;
 transition: 1s;
 cursor: pointer;
}
.socialicn:hover{
  transform: translateY(-5px);
}
.btnfooter{
  background-color: #40a737;
  border: none;
  color: #ffffff;
  padding: 10px 20px 10px 20px;
  border-radius: 20px;
  margin-top: 30px;
  font-size: 14px;
}
.btnfooter:hover{
  background-color: #40a737;
  border: none;
  color: #ffffff;
  padding: 10px 20px 10px 20px;
}
.foottext{
  background-color: transparent;
  border: none;
}
.foottxtin{
  display: flex;
  align-items: center;
  width: 80%;
  margin-top: 0px;
  border-bottom: 2px solid #000000;
  
}
.iconsfoot{
  display: flex;
}
.hrfooter{
 display: none;
}
@media screen and (max-width: 768px) {
  .footertxt{
      margin: 20px;
      font-size: 14px;
      text-align: center;
    }
    .iconsfoot{
      display: flex;
      justify-content: center;
    }
    .foottxtin{
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .hrfooter{
      width: 100%;
      display: block;
    }
}
.custom-link {
color: white; 
text-decoration: none; 
}