.dashmain {
}

.bgmaindash {
    width: 100%;
    background: linear-gradient(90deg, rgb(228, 251, 220) 37%, rgb(183, 253, 178) 70%);
    height: 300px;
}

.userdash {
   margin-top: -5%;
    width: 80%;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid rgb(238, 238, 238);
    padding: 40px;
    box-shadow: 25px 25px 25px 20px rgba(240, 239, 239, 0.2), 20px 25px 25px 20px rgba(236, 235, 235, 0.19);
}

.linksdash{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-right: 2px solid #55efc4;
} 
.imgdash{
    height: 100px;
    width: 100px;
    overflow: hidden;
    border-radius: 50%;
}
.btnlnk{
    background-color: transparent;
    border: 1px solid #55efc4;
    color: #000000;
    margin-top: 10px;
    width: 180px;
}
.btnlnk:hover{
    background-color: transparent;
    border: 1px solid #d35400;
    color: #d35400;
}
@media screen and (max-width: 1169px) {
    .btnlnk{
        background-color: transparent;
        border: 1px solid #55efc4;
        color: #000000;
        margin-top: 10px;
        width: 130px;
    }
}
@media screen and (max-width: 765px) {
    .userdash {
        margin-top: -10%;
         width: 80%;
         border-radius: 10px;
         background-color: #ffffff;
         border: 1px solid rgb(238, 238, 238);
         padding: 40px;
         box-shadow: 25px 25px 25px 20px rgba(240, 239, 239, 0.2), 20px 25px 25px 20px rgba(236, 235, 235, 0.19);
     }
}
@media screen and (max-width: 460px) {
    .userdash {
        margin-top: -40%;
         width: 95%;
         border-radius: 10px;
         background-color: #ffffff;
         border: 1px solid rgb(238, 238, 238);
         padding: 10px;
         box-shadow: 25px 25px 25px 20px rgba(240, 239, 239, 0.2), 20px 25px 25px 20px rgba(236, 235, 235, 0.19);
     }
     .btnlnk{
        background-color: transparent;
        border: 1px solid #55efc4;
        color: #000000;
        margin-top: 10px;
        width: 180px;
    }
}