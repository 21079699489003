@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins&family=Prompt:wght@300;500&display=swap');


.titleNavbar {
    font-family: 'Montserrat', sans-serif;
    color: #000;
    font-weight: bold;
    font-size: 14PX;
    text-decoration: none;
    margin: 8px;
}

.titleNavbar:hover {
    color: rgb(242, 156, 81);
}

#upload_btn {
    box-sizing: border-box;
    margin: 0;
    min-width: 10%;
    height: 10%;
    display: inline-block;
    text-decoration: none;
    font-size: 10px;
    color: #fff;
    background-color: #2B97A4;
    border: 0;
    font-family: DM Sans;
    font-weight: 0;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
}


#upload_btn:hover {
    background-color: #051646;
}

#upload_btn:active {
    background-color: #020718;
}

#upload_btn:focus {
    background-color: #020718;
    outline: none !important;
    box-shadow: none !important;
}

.navbar-light .navbar-nav .nav-link {
    color: #323232;
}

.headerLink {
    font-family: DM Sans;
    font-size: 14px;
    color: #000000 !important;
    font-weight: 700;
    text-decoration: none;
    background-color: transparent;
    font-family: 'Montserrat', sans-serif;
}

.headerLink:focus {
    color: #323232 !important;
    font-weight: 700;
    display: inline-block;
    position: relative;
    text-decoration: none;
    background-color: transparent;
}


.headerLink::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #55efc4;
    transition: width .3s;
}

.headerLink:hover::after {
    width: 100%;

}


.BgNavbar {
    background: #ffffff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
}

.navmain .lgnav {
    font-weight: bolder;
    font-size: 25px;
    color: #000000;
    text-decoration: none;
}

.navmain .nav22 .linenav {
    list-style: none;
}

.navmain .nav22 .linenav .linenav2 {
    position: relative;
    float: left;
}

.navmain .nav22 .linenav .linenav2 .txtnav {
    font-size: 18px;
    padding: 20px;
    color: #000000;
    text-decoration: none;
    display: block;
    font-weight: 600px;
}

.navmain .nav22 .linenav .linenav2 .txtnav:hover {
    color: #55efc4;

}

.navmain .nav22 .linenav .linenav2 .dropnav {
    list-style: none;
    position: absolute;
    left: 0;
    width: 200px;
    background: #ffffff;
    display: none;

}

.navmain .nav22 .linenav .linenav2 .dropnav .dropnav2 {
    text-decoration: none;
    font-size: 20px;
    color: #333;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, .1);

}

.navmain .nav22 .linenav .linenav2 .dropnav .dropnav2 .droptxtnav:hover {
    color: #55efc4;
}

.navmain .nav22 .linenav .linenav2 .dropnav .dropnav2 .droptxtnav {
    text-decoration: none;
    font-size: 20px;
    padding: 20px;
    color: #333;
    text-decoration: none;
    display: block;
    left: 0;
}

.navmain .nav22 .linenav .linenav2:focus-within>.dropnav,
.navmain .nav22 .linenav .linenav2:hover>.dropnav {
    display: initial;
}

#menu-bar {
    display: none;
}

.navmain label {
    font-size: 18px;
    color: #000000;
    cursor: pointer;
    display: none;
    font-weight: bold;
}

.serchint {
    background-color: #f6f6f4;
    padding-right: 15px;
    margin-left: 0px;
    border-radius: 8px;
}

.searchinput {
    height: 42px;
    /* width: 350px; */
    border-radius: 8px;
    padding: 12px;
    background-color: #f6f6f4;
    border: none;

}

.logInpage {
    text-decoration: none;
    color: #000000;
    font-size: 18px;
    margin-left: 15px;
}

.logInpage:hover {
    color: #55efc4;
}

.calllink {
    position: relative;
    text-decoration: none;
}

.calllink .calltext {
    font-size: 18px;
    padding: 20px;
    color: #000000;
    text-decoration: none;
    display: block;
    font-weight: 600px;
}

.calllink .calldrp {
    list-style: none;
    position: absolute;
    left: 0;
    width: 200px;
    background: #ffffff;
    display: none;
}

.cityPullRight {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.sidebarlinks {
    display: flex;
    flex-direction: column;
}

.sidebarlin {
    font-size: 16px;
    margin-left: 20px;
    margin-top: 10px;
    color: #000000;
    font-weight: 500;
    transition: 1.5s;
    text-decoration: none;
}

.sidebarlin:hover {
    color: #3498db;
}

.serchint10 {
    background-color: #f6f6f4;
    padding-right: 15px;
    margin-left: 0px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.searchinput10 {
    height: 42px;
    width: 320px;
    border-radius: 8px;
    padding: 12px;
    background-color: #f6f6f4;
    border: none;
}
.bgsidebar{
    background-color: #40a737;
    height: auto;
    padding: 10px 20px 10px 20px;
    color: #ffffff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btnside{
    height: 40px;
    width: 100px;
    background-color: #ffffff;
    color: #000000;
    border: none;
}
.btnside:hover{
    height: 50px;
    width: 100px;
    background-color: #ffffff;
    color: #40a737;
    border: none;
}

#basic-nav-dropdown {
    color: #000 !important ; 
    font-weight: 500;
}
#basic-nav-dropdown:hover {
  color:rgb(242, 156, 81) !important;
 
}

@media screen and (max-width:1246px) {
    .searchinput {
        /* width: 80%; */
    }

    .cityPullRight {
        justify-content: flex-end;
        width: 100%;
    }
  

}

@media screen and (max-width: 1100px) {

    .cityPullRight {
        justify-content: flex-end;
        width: 100%;
    }

    .navmain label {
        display: initial;
    }

    .navmain .nav22 {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: #ffffff;
        border-top: 1px solid rgba(0, 0, 0, .1);
        display: none;
    }

    .navmain .nav22 .linenav .linenav2 {
        width: 100%;
    }

    .navmain .nav22 .linenav .linenav2 .dropnav {
        position: relative;
        width: 100%;
    }

    .navmain .nav22 .linenav .linenav2 .dropnav .dropnav2 {
        background: #eee;
    }

    #menu-bar:checked~.nav22 {
        display: initial;
    }
}

.logomain {
    height: 50px;
    width: 150px;
}
.logomainHeader {
    height: 50px;
    width: 150px;
}
.logomainHeader2 {
    height: 50px;
    width: 150px;
}


@media screen and (max-width: 992px) {
    .cityPullRight {
        display: flex;
        flex-direction: column;
        width: auto;
    }

    .serchint {
        display: none;
    }

    .searchIcon {
        visibility: hidden;
    }

    .BgNavbar {
        padding: 0px;
    }


}

@media screen and (max-width: 976px) {

    .cityPullRight {
        display: flex;
        flex-direction: column;
        width: auto;
    }

    .serchint {
        margin-left: 5px;
        width: 90%;
    }

    .searchIcon {
        visibility: hidden;
    }

}


@media screen and (max-width: 830px) {
    .logomain {
        height: 50px;
        width: 150px;
    }

    .cityPullRight {
        display: flex;
        flex-direction: column;
        width: auto;
    }
}

@media screen and (max-width: 570px) {
    .logomain {
        height: 40px;
        width: 120px;
        margin: auto; /* Add this line to center the logo */
        display: block; /* Ensure it's displayed as a block element */
    }

    .logomainHeader {
        height: 40px; 
        width: 120px;
        margin: auto; /* Add this line to center the logo */
        display: block; /* Ensure it's displayed as a block element */
        margin-left: -15.1rem !important;
    }
    .logomainHeader2 {
        height: 40px;
        width: 120px;
        margin: auto; /* Add this line to center the logo */
        display: block; /* Ensure it's displayed as a block element */
        margin-left: 3.9rem !important;
    }

    .cityPullRight {
        display: flex;
        flex-direction: column;
        width: auto;
    }

    .serchint {
        margin-left: 0px;
    }

    .searchinput {
        height: 40px;
        /* width: 250px; */
        border-radius: 8px;
        padding: 12px;
        background-color: #f6f6f4;
        border: none;

    }
}

@media screen and (max-width: 356px) {
    .logomain {
        height: 40px;
        width: 120px;
        margin-left: -116px !important;
    }

    .cityPullRight {
        display: flex;
        flex-direction: column;
        width: auto;
    }

    .searchinput {
        height: 40px;
        /* width: 180px; */
        border-radius: 8px;
        padding: 12px;
        background-color: #f6f6f4;
        border: none;

    }
}

@media screen and (max-width: 258px) {
    .logomain {
        height: 40px;
        width: 120px;
    }

    .cityPullRight {
        display: flex;
        flex-direction: column;
        width: auto;
    }

    .searchinput {
        height: 40px;
        /* width: 150px; */
        border-radius: 8px;
        padding: 12px;
        background-color: #f6f6f4;
        border: none;

    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    /* Your CSS styles for iPad screens here */
    .logomainHeader{
        margin-left: -32rem;
    }
    .logomainHeader2{
        margin-left: -32rem;
    }
}