.buy-order-main-card {
  margin-top: 10px;
  padding: 1rem;
}

.order-id {
  font-weight: bold;
  font-size: 18px;
}
.address .toast {
  max-width: 150px !important;
  position: absolute;
}
.address .toast-body {
  background-color: #fff;
}

.buycardHeader {
  margin: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.order-date {
  color: grey;
}

.productdetailCard {
  margin: 2px;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid green; */
}
.buyorderPname {
  flex-basis: 100px;
}

.buy-card-footer {
  display: flex;
  justify-content: space-between;
}

.buyorderimg {
  margin: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid grey ; */
  border-radius: 10%;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.footer-heading {
  font-weight: bold;
  font-size: 14px;
}
.productPrice {
  color: green;
  font-weight: bold;
  font-size: 16px;
}
.priceQuant {
  margin: 10px;
}
.buyorderPname {
  font-weight: bold;
  margin: 10px;
  color: grey;
}
.price-condi-section {
  /* align-self: flex-start; */
  flex: -1 300px;
}
.orderimg {
  width: 60%;
  max-width: 100%;
}
.buyordeeBody {
  display: flex;
  margin: 10px;
  /* flex-direction: row-reverse; */
}
.buy-cardFooter {
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 500px) {
  .orderimg {
    width: 5rem;
  }
  .priceQuant {
    font-size: 10px;
  }
  .productdetailCard {
    /* flex-direction: column; */
  }
  .buycardHeader {
    flex-direction: column;
  }
  .orderhead {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }
  .productdetailCard,
  .oderv {
    font-size: 12px;
  }
  .codi {
    font-size: 10px;
    margin-top: 10px;
  }
}
