.gadget-sale-preparation {
    font-family: 'Arial', sans-serif;
    max-width: 1150px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.gadget-sale-preparation h1 {
    color: 0000;
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
}

.gadget-sale-preparation h2 {
    color: black;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 15px;
}

.gadget-sale-preparation p,
.gadget-sale-preparation ul {
    color: 0000;
    line-height: 1.6;
}

.gadget-sale-preparation ul {
    padding-left: 20px;
}

.gadget-sale-preparation ul li {
    margin-bottom: 5px;
}

.gadget-sale-preparation section {
    margin-bottom: 20px;
}

.gadget-sale-preparation section:last-child {
    margin-bottom: 0;
}

.device-handover-content {
    font-family: 'Arial', sans-serif;
    max-width: 1150px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.device-handover-content h1 {
    color: #000;
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
}

.device-handover-content h2 {
    color: black;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 15px;
}

.device-handover-content p,
.device-handover-content ul {
    color: #000;
    line-height: 1.6;
}

.device-handover-content ul {
    padding-left: 20px;
}

.device-handover-content ul li {
    margin-bottom: 5px;
}

.device-handover-content section {
    margin-bottom: 20px;
}

.device-handover-content section:last-child {
    margin-bottom: 0;
}
