.testmain {
    margin-top: 10px;
    padding: 20px;
    margin-bottom: 20px;
}

.card1test {
    padding: 20px;
    height: 100%;
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.card1test2 {
    padding: 40px 20px 50px 20px;
    border-radius: 20px;
    text-align: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.txttest {
    margin-top: 30px;
    font-weight: 500;
}

.txttest2 {
    margin-top: 10px;
    text-align: center;
}

.btntest {
    width: 50%;
    background-color: #fed700;
    color: #000000;
    border: none;
}

.btntest:hover {
    width: 50%;
    background-color: #d35400;
    color: #ffffff;
    border: none;
}

.testtxt {
    color: #818EA0;
    margin-top: 30px;
}

.imgtst {
    height: 60px;
    width: 60px;
    border-radius: 50%;
}

.tesxtcardpro {
    overflow: hidden;
    border-radius: 15px;
    padding: 0px 0px 0px 30px;
    background: #ffffff;
    margin-top: 50px;

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.tesxtcardpro:hover .imgservice {
    transform: translateX(-30px);
}

.textbtn2 {
    background-color: transparent;
    border: 2px solid #40a737;
    color: #40a737;
    font-size: 12px;
    padding: 10px 20px 10px 20px;
}

.textbtn2:hover {
    background-color: #40a737;
    border: none;
    border: 2px solid #40a737;
    color: #ffffff;
    font-size: 12px;
    padding: 10px 20px 10px 20px;
}

.whatsappcard {
    width: 100%;
    background-color: #ffffff;
    border-radius: 20px;
    margin-top: 20px;
    cursor: pointer;
    padding: 15px;
}

.newtestpro {
    border-radius: 15px;
    background: #ffffff;
    overflow: hidden;
    padding: 0px 0px 20px 25px;
    width: 100%;
    margin-top: 30px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.newtestpro:hover .imgservice {
    transform: translateX(-10px);
}

@media screen and (max-width: 924px) {
    .testmain {
        margin-top: 40px;
        padding: 20px;
    }
}