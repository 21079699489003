.category-modal-container {
  font-size: 12px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  margin: 1rem;
  border: 0;
  -webkit-box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

  transition: 0.3s ease-in-out;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}
.productdescmodal {
  backdrop-filter: blur(5px);
}
.category-modal-container:hover {
  border: 1px solid #0eec0e;
}

.cardShadow1 {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border: 0px;
  border-radius: 15%;
  margin-top: 30px;
  transition: 0.5s ease-in-out;
}

.cardShadow1:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: rgb(191, 233, 209);
  background: radial-gradient(
    circle,
    rgba(191, 233, 209, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}

.cardShadow2 {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border: 0px;

  border-radius: 15%;

  margin-top: 30px;
  transition: 0.5s ease-in-out;
}

.cardShadow2:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  background: rgb(235, 211, 209);
  background: radial-gradient(
    circle,
    rgba(235, 211, 209, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}

.cardShadow3 {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border: 0px;

  border-radius: 15%;

  margin-top: 30px;
  transition: 0.5s ease-in-out;
}

.cardShadow3:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: rgb(244, 226, 198);
  background: radial-gradient(
    circle,
    rgba(244, 226, 198, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}

.cardShadow4 {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border: 0px;

  border-radius: 15%;

  margin-top: 30px;
  transition: 0.5s ease-in-out;
}

.cardShadow4:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: rgb(183, 221, 245);
  background: radial-gradient(
    circle,
    rgba(183, 221, 245, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}

.cardShadow5 {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */

  border-radius: 15%;
  border: 0px;

  margin-top: 30px;
  transition: 0.5s ease-in-out;
}

.cardShadow5:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: rgb(187, 246, 234);
  background: radial-gradient(
    circle,
    rgba(187, 246, 234, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}

.cardShadow6 {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border: 0px;

  border-radius: 15%;
  margin-top: 30px;
  transition: 0.5s ease-in-out;
}

.cardShadow6:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: rgb(227, 195, 241);
  background: radial-gradient(
    circle,
    rgba(227, 195, 241, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}

.modelImgCard {
  width: 100%;
  /* height: 70%; */
}
.modelImgCardcondition {
  width: 60%;
  /* height: 70%; */
}

@media screen and (max-width: 460px) {
  .modelImgCardcondition {
    width: 100%;
    /* height: 70%; */
  }
  .categoryModel .card-text {
    margin: 0px !important;
  }
  .categoryModel .card-body {
    padding: 0px !important;
  }
  .mbtext .card-text {
    font-size: 12px !important;
    margin: 0px !important;
    font-weight: bold !important;
  }
  .mbtext .lspara {
    font-size: 10px !important;
  }
}
