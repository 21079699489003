@media only screen and (max-width: 500px) {

    .profile-box {
        flex-direction: column;
    }

    .profile-content-box {
        width: 100% !important; 
    }
}

.profile-content-box {
    padding: 5px 20px;
    width: 50%;
}