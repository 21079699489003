.steps-container {
    margin-top: 1rem;
    /* box-shadow: 0 16px 11px rgba(33,33,33,.1); */
    box-shadow: rgba(60, 64, 67, .3) 0px 1px 2px 0px, rgba(60, 64, 67, .15) 0px 1px 3px 1px;
    background-color: #fff;
    border-radius: 0px;
    text-decoration: none;
    color: #222;
    font-size: 12px;
    cursor: pointer;
    transition: .3s ease-in-out;
    font-weight: bold;
    /* height: %; */
}


.steps-container:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}



.steps-container-yes {
    margin-top: 1rem;

    /* box-shadow: 0 16px 11px rgba(33,33,33,.1); */
    box-shadow: rgba(60, 64, 67, .3) 0px 1px 2px 0px, rgba(60, 64, 67, .15) 0px 1px 3px 1px;
    background-color: #fff;
    border-radius: 0px;
    text-decoration: none;
    color: #222;
    font-size: 12px;
    cursor: pointer;
    transition: .3s ease-in-out;
    border: 1px solid #0eec0e;
    font-weight: bold;

}

.steps-container-no {
    margin-top: 1rem;

    box-shadow: 0 16px 11px rgba(33, 33, 33, .1);
    /* box-shadow: 0 16px 11px rgba(33,33,33,.1); */
    box-shadow: rgba(60, 64, 67, .3) 0px 1px 2px 0px, rgba(60, 64, 67, .15) 0px 1px 3px 1px;
    background-color: #fff;
    border-radius: 0px;
    text-decoration: none;
    color: #222;
    font-size: 12px;
    cursor: pointer;
    transition: .3s ease-in-out;
    border: 1px solid #e74c3c;
    font-weight: bold;
}



.question-icon {
    transition: all .3s ease-in-out;
    -webkit-filter: drop-shadow(0px 0px 15px #95cead);
    filter: drop-shadow(0px 0px 15px #95cead);
}

.question-icon:hover {
    /* transform: scale(1.1); */
}




.changeAnimation {
    animation: fadeInAnimation 3s !important;
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.changeAnimation2 {
    animation: fadeInAnimation2 3s !important;
}

@keyframes fadeInAnimation2 {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.changeAnimation3 {
    animation: fadeInAnimation3 3s !important;
}

@keyframes fadeInAnimation3 {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.changeAnimation4 {
    animation: fadeInAnimation3 4s !important;
}

@keyframes fadeInAnimation4 {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* .containerStyle {
    background: rgb(145, 237, 131);
    background: linear-gradient(180deg, rgba(145, 237, 131, 1) 17%, rgba(255, 255, 255, 1) 100%);
} */

.imgShadow1 {
    -webkit-filter: drop-shadow(0px 0px 15px #d35400);
    filter: drop-shadow(0px 0px 15px #d35400);
    /* width: 100%; */
    /* height: 100%; */
    margin-top: 34px;
}

.imgShadow2 {
    -webkit-filter: drop-shadow(0px 0px 15px #2ecc71);
    filter: drop-shadow(0px 0px 15px #2ecc71);
    width: 100%;
    /* height: 100%; */
    margin-top: 34px;
}

.imgShadow3 {
    -webkit-filter: drop-shadow(0px 0px 15px #1abc9c);
    filter: drop-shadow(0px 0px 15px #1abc9c);
    width: 100%;
    /* height: 100%; */
    margin-top: 34px;
}

.imgShadow4 {
    -webkit-filter: drop-shadow(0px 0px 15px #2980b9);
    filter: drop-shadow(0px 0px 15px #2980b9);
    width: 100%;
    /* height: 100%; */
    margin-top: 34px;
}

.imgShadow5 {
    -webkit-filter: drop-shadow(0px 0px 15px #f39c12);
    filter: drop-shadow(0px 0px 15px #f39c12);
    width: 100%;
    /* height: 100%; */
    margin-top: 34px;
}

.imgCol {
    margin-left: -25px;
    margin-top: 0px;
}


.answers {
    /* justify-content: center; */
    background: #f1f1f3;
    border-radius: 10px;
    margin-top: 5px;
    padding: 4px;
    border: 1px solid #c3c3c5;
    font-weight: 600;
    font-size: 12;
    width: 50%;
}

.labelClass {
    font-size: 15px;
    margin-top: 20px;
    font-weight: bold;
}


@media screen and (max-width: 768px) {

    [title] {
        position: relative;
        display: inline-flex;
        justify-content: center;
      }
      [title]:focus::after {
        content: attr(title);
        position: absolute;
        top: 90%;
        color: #000;
        background-color: #fff;
        border: 1px solid;
        width: fit-content;
        padding: 3px;
        font-size:10px;
}
    


    .imgShadow1 {
        width: 100%;
        /* height: 100%; */
        /* margin-top: 12px; */
    }

    .imgShadow2 {
        width: 100%;
        /* height: 100%; */
        /* margin-top: 12px; */
    }

    .imgShadow3 {
        width: 100%;
        /* height: 100%;
        margin-top: 12px; */
    }

    .imgShadow4 {
        width: 100%;
        /* height: 100%; */
        /* margin-top: 12px; */
    }

    .imgShadow5 {
        width: 100%;
        /* height: 100%; */
        /* margin-top: 12px; */
    }


    .imgCol {
        margin-left: -31px;
        margin-top: 0px;
    }

    .answers {
        margin-top: 5px;
        padding: 0px;
        font-size: 12;
        width: 100%;
    }

    .labelClass {
        font-size: 12px;
        margin-top: 20px;
        font-weight: bold;
    }
   .answer-option{
    margin-left: 10px;
   }
}