.step4textBox {
    display: block;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    transition: .3s ease-in-out;
    cursor: pointer;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #e8ebec;
}

.step4textBoxClicked {
    display: block;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    transition: .5s ease-in-out;
    cursor: pointer;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid #2ecc71;
    background-color: #e8ebec;
}

.step4textBox:hover {
    background-color: #ecf0f1;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.step8textBox {
    display: block;
    padding: 20px;
    padding-left: 10px;
    border-radius: 10px;
    width: 90%;
    /* height: 100px; */
    transition: .3s ease-in-out;
    cursor: pointer;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #e8ebec;
    
}
/* .step8textBox :hover {
    background-color: #ecf0f1;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
} */