.slick-arrow,
.slick-arrow:hover {
  background-color: black !important;
  border-radius: 50%;
}
.paccordian button{
  font-weight: bold !important;
}
.addtocart-btn {
  width: 40%;
  border-color: rgb(64, 167, 55);
}
.buy-now-btn {
  width: 40%;
  background-color: rgb(64, 167, 55);
}
.container-buy-p-detail {
  display: flex;
  justify-content: space-between;
}
.gallerypart {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 0px;
  width: 40%;
  margin-top: 10px;
  margin-right: 15px;
  /* height: 50%; */
}
.side-thubnail {
  height: 480px;
  overflow: auto;
}
.side-thubnail::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.despart {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 0px;
  width: 50%;
  margin-top: 10px;
  padding: 10px 20px;
  /* height: 50%; */
}

.videobtn a {
  text-decoration: none;
  color: #fff;
}
.side-images {
  margin: 15px;
  border-radius: 15px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.side-images img {
  border-radius: 15px;
}
.tab-header {
  margin-left: 340px;
  margin-bottom: 10px;
}
.warranty-desc-sec {
  align-items: center;
  justify-content: center;
}
.productmaindesk {
  display: block;
}
.productslidermobile {
  display: none;
}

@media only screen and (max-width: 500px) {
  .slider-box-mob{
    font-size: 9px ;
    width: 93px!important;
  }
  .productmaindesk {
    display: none !important;
  }
  .productslidermobile {
    display: block;
  }
  .cartfooterbtn {
    flex-direction: column;
  }
  .addtocart-btn {
    width: 100%;
  }
  .buy-now-btn {
    margin-top: 10px;
    width: 100%;
  }
  .container-buy-p-detail {
    flex-wrap: wrap;
  }
  .gallerypart {
    width: 105%;
  }
  .despart {
    width: 100%;
    margin-top: 1rem;
    padding: 0px;
  }
  .tab-header {
    margin-bottom: 10px;
    margin-left: 0px;
    font-size: 12px;
    font-weight: 500;
  }
  .side-thubnail {
    width: 30% !important;
    height: 320px;
    overflow: auto;
    display: none;
  }

}

