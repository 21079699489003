.BuycategoryImage {
  width: 15rem;
}
.Price_card img {
  width: 10rem;
  border-radius: 50%;
  margin: 5px;
}
.Choose_price {
  display: flex;
  justify-content: center;
  padding: 0px 100px;
}

.Sub-heading-buy {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.buy-cat-slider {
  margin: 5rem;
}

.slider-box {
  cursor: pointer;
}

.choosePrice {
  margin-top: 2rem;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
}
.exploreram {
  margin-top: 3rem;
  margin-bottom: 4rem;
}
.filtercount {
  display: block;
  margin-top: 11px;
}

.divider {
  display: flex;

  &:before,
  &:after {
    content: "";
    flex: 1;
  }
}

.line {
  align-items: center;
  margin: 3em;

  &:before,
  &:after {
    height: 1px;
    margin: 0 1em;
  }
}
.glow {
  &:before,
  &:after {
    height: 6px;
    -webkit-filter: blur(0px);
    border-radius: 5px;
  }

  &:before {
    background: linear-gradient(to right, #2db623, #a7d7dc);
  }

  &:after {
    background: linear-gradient(to left, #2db623, #a7d7dc);
  }
}

.gradient {
  align-items: stretch;
  margin: 1em 0;
  height: 2em;
  line-height: 2em;
  color: white;
  background: black;

  &:before {
    background: linear-gradient(to right, white, black);
  }

  &:after {
    background: linear-gradient(to left, white, black);
  }
}

.donotcross {
  margin-top: 2rem;
  overflow: hidden;
  align-items: center;
  background: #2db623;
  color: black;
  height: 2em;
  line-height: 2em;

  &:before,
  &:after {
    background: white;
    padding: 50px 0;
    height: 0;
    transform: rotate(45deg);
  }
}
.Main_page {
  background-color: #ececec;
}
.trendingProName {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

@media only screen and (max-width: 500px) {
  .lessthen .titlep {
    font-size: 12px !important;
  }
  .Choose_price {
    flex-wrap: wrap;
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    width: 100%;
    margin: 3rem 0px;
    justify-content: center !important;
    gap: 1rem;
  }
  .Choose_price-2 {
    flex-wrap: wrap;
    padding: 0px !important;
    margin: 0px;
    box-sizing: border-box;
    width: 100%;
    margin: 3rem 0px;
    justify-content: space-around !important;
  }
  .phonebanner-2 {
    padding: 5px 0px !important;
  }
  .Choose_price-2 .price-circle {
    width: 105px !important;
    height: 105px !important;
  }
  .price-circle {
    width: 100px !important;
    height: 100px !important;
    margin: 5px 0px !important;
  }
  .Choose_price .price-circle h3,
  .Choose_price .price-circle h4,
  .Choose_price .price-circle h5 {
    font-size: 12px !important;
  }
  .Price_card img {
    width: 10rem;
  }
  .trend_img img {
    width: 80px !important;
    height: 80px !important;
  }
  .buy-cat-slider {
    margin: 10px;
  }
  .choosePrice {
    margin-top: 2rem;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }
  .line {
    margin: 0px;
    margin-top: 2rem;
  }
  .pmodel {
    width: 40% !important;
  }
  .pmodel-2 {
    width: 60% !important;
  }
  .productslider .slider-box {
    margin-top: 40px;
  }
  .productslider .slider-box .card {
    width: 8rem !important;
  }
  .productslider .slider-box .card-title {
    font-size: 0.8rem !important;
  }
  .productslider .slider-box p {
    font-size: 15px !important;
    margin: 0px !important;
  }

  .donotcross {
    overflow: hidden;
    align-items: center;
    background: #2db623;
    color: black;
    height: 2em;
    line-height: 2em;

    &:before,
    &:after {
      background: white;
      padding: 11px 0;
      height: 0;
      transform: rotate(45deg);
    }
  }
}

/* new css */

.price-circle {
  border: 0px solid black;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(#fff, #06a621);
}
.Choose_price-2 {
  display: flex;
  justify-content: center;
  padding: 0px 200px;
}

.Choose_price-2 .price-circle {
  width: 150px;
  height: 150px;
  background-image: linear-gradient(#fff, #06a621);
}
.pmodel {
  width: 20%;
}
@media only screen and (max-width: 400px) {
  .productslider .slider-box .treanding_cards {
    width: 7.6rem !important;
  }
}
@media only screen and (max-width: 1024px) { /* Adjust this breakpoint as per iPad width */
  .Choose_price {
    padding: 0px; /* Remove the padding */
    justify-content: space-around; 
    flex-wrap: wrap;/* Adjust the layout to evenly space the circles */
    justify-content: center !important;
    gap: 1rem;
  }
  .Choose_price-2 {
    flex-wrap: wrap;/* Adjust the layout to evenly space the circles */
    justify-content: center !important;
    padding: 0px;
  }
}

@media only screen and (max-width: 820px) { 
  .buy-cat-slider-local{
    margin: 0 !important;
  }
  .slider-box-local{
    /* background-color: red !important; */
  }
}

@media only screen and (max-width: 768px) { 
  .buy-cat-slider-local{
    margin: 0;
  }
  .slider-box-local{
    /* background-color: red !important; */
  }
}
