.term-container {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: justify;
}

.term-content {
  margin-top: 20px;
  font-family: "Arial", sans-serif;
  color: #333;
}

.term-content h1 {
  font-size: 24px;
  color: #333;
}

.term-content p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
}

.highlighted-tagline {
  color: #3bb61a; /* Choose a color that fits your website's theme */
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}
