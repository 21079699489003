/* Slider.css */

.swiper-container-wrapper {
  position: relative;
  overflow: hidden;
}

#mySwiper {
  width: 100%;
  position: relative;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: 2px solid grey;
  z-index: 10;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next {
  right: -50px; 
}

.swiper-button-prev {
  left: -50px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 1rem !important;
  color: gray !important;
  text-align: center;
}

@media screen and (max-width: 520px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
}

.swiper-pagination {
  position: absolute !important;
  bottom: -20px !important; 
  left: 50%;
  transform: translateX(-50%); 
  z-index: 10;
  display: flex;
  justify-content: center;
}

.image-container {
  display: flex;
  margin-top: 10px;
  overflow: hidden; 
}

.image-container img {
  width: 100%;
  object-fit: cover;
  border-radius: 10%;
}

.image-container img:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
 #mySwiper .image-container img {
    max-height: 200px;
  }
}

@media screen and (max-width: 520px) {
  #mySwiper .image-container img {
    max-height: 180px;
  }

 #mySwiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

#mySwiper .swiper {
  width: 100%;
  height: 100%;
  overflow: hidden; 
}

#mySwiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; 
}

@media screen and (max-width: 768px) {
  #mySwiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (min-width: 900px) and (max-width: 1120px) {
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--swiper-theme-color);
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}
