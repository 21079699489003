.productNameCss {
    margin-top: 10px;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 30px;
}

.productVariantCss {
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 15px;
    margin-top: 10px;
}

.nextBtn {
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    background-color: #40a737;
    border: 0px;
    border-radius: 0px;
    margin-top: 20px;
}

.productImage {
    width: 100%;
    height: 100%;
}

.brandImgShadow{
    -webkit-filter: drop-shadow(0px 0px 30px #3cea85);
}

.storageBtn {
    font-weight: bold;
    padding: 5px;
    background-color: transparent;
    color: #000;
    border: 2px solid #000;
    border-radius: 0px;
    width: 200px;
    margin-top: 10px
}

.ramBtn {
    font-weight: bold;          
    padding: 5px;
    background-color: transparent;
    color: #000;
    border: 2px solid #000;
    border-radius: 0px;
    width: 200px;
    margin-top: 10px
}
.warningDiv{
    margin-top: 10px !important;
}

@media screen and (max-width: 600px) {
    .productNameCss {
        text-align: center;
    }

    .productVariantCss {
        text-align: center;
    }

  

    .nextBtn {
        margin: auto;
        margin-top: 10px;
    }
}
