.txtinpt{
  margin-top: 20px;
  border: none;
  border-bottom: 2px solid #000000;
}

.formbox{
    border-radius: 20px;
    padding: 20px;
    border: 1px solid red;
    box-shadow: 15px 15px 15px 10px rgba(240, 239, 239, 0.2), 10px 15px 15px 10px rgba(236, 235, 235, 0.19);
}
/* .table-head-question{
  
} */

.box-shadow{
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;}