



#youtube-reviews .owl-prev,
#youtube-reviews .owl-next {
  position: absolute;
  top: 50%;
  border-radius: 50% !important;
  transform: translateY(-100%);
  width: 50px;
  height: 50px;
  font-size: 20px !important;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  z-index: 1000;
  border:3px solid gray !important;
  background-color: whitesmoke !important;
  color: gray !important;
}



#youtube-reviews .owl-nav .owl-prev{
  left: -10px !important;
}

#youtube-reviews .owl-nav .owl-next{
  right: -10px !important;
}

  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: gray;
    transform: translate3d(0px, -50%, 0px) scale(0.8);
  }
  .owl-carousel .owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .owl-carousel .owl-dots .owl-dot{ 
    display: inline-block;
  }
  .owl-carousel .owl-dots .owl-dot span{ 
    /* background: #eaa400; */
    background: #56423D;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;
  } 

  #youtube-reviews .card-img{
    height: 315px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

 
 

  #youtube-reviews .card-body{
    padding: 0px;
  }



  


 

  #youtube-reviews .brand-card{
    position: relative;
    margin: 10px ;
    border-radius:20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  #playButton, #playButton:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    cursor: inherit;
}
#playButton:hover{
  cursor: pointer;
}
.card-wrapper {
    position: relative; 
  }
  
  #playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 68px;
    height: 48px;
    background-color: transparent;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="red"/><path d="M45 24 27 14v20" fill="white"/></svg>');
    z-index: 1;
    border: 0;
    border-radius: none;
  }
  
 
  #youtube-reviews .parent-container {
    border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
    background-color: #378b29;
   display: flex;
  height: 60px; 
  color: white;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}


 




  #youtube-reviews .trending-gad-title{
    padding: 5px;
    font-size: 1.1rem;
    font-weight: bolder;
    font-family:'Montserrat', sans-serif;
    height: 70px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  #youtube-reviews .owl-carousel  #youtube-reviews .owl-nav.disabled,  #youtube-reviews .owl-carousel  #youtube-reviews .owl-dots.disabled{
    display: block !important;
  }

  #youtube-reviews .owl-carousel #youtube-reviews .owl-dots #youtube-reviews .owl-carousel #youtube-reviews  .owl-nav{
    display: block;
  }

 


  @media screen and (min-width: 600px)  {
    #youtube-reviews .owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled{
      display: block !important;
    }

    #youtube-reviews .owl-carousel .owl-dots .owl-carousel .owl-nav{
      display: block;
    }
  }





  @media screen and (min-width: 100px) and (max-width:500px) {
    #youtube-reviews .card-img{
      height: 400px;
      object-fit: cover;
    }
  }

  @media screen and (min-width:481px) and (max-width:768px){
    #youtube-reviews .card-img{
      height: 250px;
    }
    #youtube-reviews .brand-card{
      margin: 8px; 
    }
 
  
    #youtube-reviews .trending-gad-title{
      font-size: 1.1rem;
      height: 50px;
      display: -webkit-box;
      -webkit-line-clamp: 2; 
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    #youtube-reviews .parent-container {
      height: 55px;
    }
  }
  @media screen and (min-width:601px) and (max-width:768px) {
    #youtube-reviews .card-img{
      height: 300px;
    }
  }
 

  @media screen and (max-width:480px){
    #youtube-reviews .card-img{
      height: 180px;
    }
    #youtube-reviews .brand-card{
      margin: 5px;   
    }

    .owl-carousel .owl-nav.disabled{
      display: none !important;
    }

    .owl-carousel .owl-dots .owl-carousel .owl-nav{
      display: none !important;
    }
    
  
    #youtube-reviews .trending-gad-title{
      font-size: 0.9rem;
      height: 40px;
      display: -webkit-box;
      -webkit-line-clamp: 2; 
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    #youtube-reviews .parent-container {
      height: 50px;
    }
  }


  @media screen and (min-width:401px) and (max-width:480px) {
    #youtube-reviews .card-img{
      height: 230px;
    }
  }
  


