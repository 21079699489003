.mainhomebg {
    background-image: url('https://cdn.shopify.com/s/files/1/0601/1351/8644/files/main-banner-1_1903x.jpg?v=1656304950;');
    height: auto;
    margin-top: 50px;
    padding: 50px;
    width: 100%;
    max-width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.txtmain {
    width: 100%;
    font-size: 45px;
    font-weight: 600;
    letter-spacing: 2px;
}
.imgslid{
    height: 100%;
    width: 100%;
    border-radius: 35px;
}

@media screen and (max-width: 900px) {
    .txtmain {
        width: 100%;
        font-size: 40px;
    }

    .mainhomebg {
        content: '';
        padding: 50px;
        width: 100%;
        color: #ffffff;
        margin-top: 30px;
    }
    .imgslid{
        height: 100%;
        width: 100%;
        border-radius: 25px;
    }
   
}

.mainbtn {
    background-color: #000000;
    padding: 10px 20px 10px 20px;
    border-radius: 25px;
    border: none;
}

.mainbtn:hover {
    color: #55efc4;
    background-color: #000000;
    padding: 10px 20px 10px 20px;
    border-radius: 25px;
    border: none;
}

@media screen and (max-width: 392px) {
    .txtmain {
        width: 100%;
        font-size: 20px;
        color: #ffffff;
    }
    .imgslid{
        height: 100%;
        width: 100%;
        border-radius: 10px;
    }
}

@media screen and (max-width: 280px) {
    .txtmain {
        font-size: medium;
    }
}


.slick-prev {
    left: 0% !important;
    z-index: 1;
}

.slick-next {
    right: 0% !important;
    z-index: 1;
}

.serchint2 {
    background-color: #f6f6f4;
    padding-right: 15px;
    margin-left: 0px;
    border-radius: 8px;
    display: none;
}
@media screen and (max-width: 992px) {

    .bannerHome .slick-next::before, .slick-prev::before{
        font-size: 12px;
    }
    .bannerHome  .btn{
     
                height: 16px !important;
                width: 16px !important;
    }
    .serchint2 {
        background-color: #f6f6f4;
        padding-right: 15px;
        margin-left: 0px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px;
    }
    .searchinput22{
        height: 40px;
        width: 100%;
        border-radius: 8px;
        padding: 12px;
        background-color: #f6f6f4;
        border: none;
        
    }
    
}