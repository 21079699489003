
.exchangeBtn{
    margin-bottom: 1rem;
    width: 100%;
    height: 5rem;
    font-size: 16px;
    font-weight: 700;
}
/* .modalBody{
    height: 15rem;
} */