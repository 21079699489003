.ourtxt {
    text-align: center;
    font-weight: 600;
    text-decoration: overline 2px #55efc4;
}

.ourcardmain {
    height: 200px;
    width: 200px;
    margin: 20px;
    box-shadow: 3px 3px 3px 3px #e3e6e7;

}

.ourcardmain:hover {
    border: 3px solid#55efc4;
}

.txtservice {
    text-align: center;
    margin-top: 10px;
    font-size: 22px;
    font-weight: 600;
}

.imgservice {
    margin-top: 10px;
    height: 55%;
    width: 50%;
    transition: 1.5s;
    cursor: pointer;
    /* margin-right: -40px; */
}

.txtsev {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.newservice {
    border-radius: 15px;
    background: #ffffff;
    overflow: hidden;
    padding: 0px 0px 20px 25px;
    width: 90%;
    margin-top: 30px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;

}

.newservice:hover .imgservice {
    transform: translateX(-10px);
}

.crdtxt {
    margin-top: 30px;
    font-weight: 600;
    font-size: 20px;
}

.crdtxt2 {
    margin-top: 10px;
    font-size: 14px;
}

.btnserv {
    background-color: #40a737;
    border: none;
    font-size: 12px;
    color: #ffffff;
    margin-top: 0px;
}

.btnserv:hover {
    background-color: #40a737;
    border: none;
    color: #ffffff;
}

.cardbgservice {
    background-color: #e7ebee;
    height: 10%;
    color: black;
    width: 100%;
}
.txtdealer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
   padding-top: 20%;
}
.txtare {
    font-size: 50px;
    font-weight: 500;
    color: #34495e;
    margin-top: auto;
}
.btndealer{
    background-color: transparent;
    border: 2px solid #000000;
    color: #34495e;
    margin-top: 20px;
}


.cardimgservice {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.freetxtservice {
    font-weight: 700;
    font-size: 20px;
    cursor: pointer;
    color: #000000;

}

.freetxtservi {
    font-size: 18px;
    display: flex;
    margin-top: 15px;
    margin-left: 10px;

}

.freetxtservicebtn {
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
    margin-left: 10px;
    color: #000000;
}

@media screen and (max-width: 924px) {
    .imgshipserv {
        height: 0px;
        width: 0px;
        display: none;
    }

    .cardimgservice {
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .freetxtservi {
        font-size: 15px;
    }

    .freetxtservicebtn {
        font-weight: 700;
        font-size: 12px;
        cursor: pointer;
        color: #000000;
    }

    .freetxtservice {
        font-size: 15px;
        cursor: pointer;
    }
    .txtare {
        font-size: 30px;
        font-weight: 500;
        color: #34495e;
        margin-top: 20px;
    }

}