
.processorBtn{
    font-weight: bold;
    padding: 5px;
    background-color: transparent;
    color: #000;
    border: 2px solid #000;
    border-radius: 0px;
    width: 100%;

}
.dropdown-menu-step3{
    width: 60%;
}
.processorBtn-desk{
    font-weight: bold;
    padding: 5px;
    background-color: transparent;
    color: #000;
    border: 2px solid #000;
    border-radius: 0px;
    width: 100%;

}

@media screen and (max-width: 600px) {
    .processorBtn {
        font-weight: bold;
        padding: 5px;
        background-color: transparent;
        color: #000;
        border: 2px solid #000;
        border-radius: 0px;
        width: 100%;
        
    }
    .dropdown-menu-step3{
        width: 90%;
    }
    .processorBtn-desk{
        width: 100%;
    
    }
    .desk-confi{
        width: 100% !important;
    }
}
