/* BlogLayout.css */

.container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
}

.main-blog {
    text-align: center;
    margin: 5%;
    margin-top: 0px;
    padding: 2%;
    margin-bottom: 30px; /* Spacing between main blog and other blogs */
    box-shadow: rgba(63, 67, 60, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}



.sub-blog {
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
}
.sub-blog :hover {
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    transform: scale(1.02);
    transition: all 0.3s ease-in-out;
}

.sub-blog img {
    width: 100%;
    height: auto;
}

.sub-blog h4 {
    font-size: 1.2em;
    margin-top: 5px;
}

.sub-blog p {
    font-size: 0.9em;
}

@media (max-width: 768px) {
    .sub-blog {
        flex-basis: 100%;
    }
    .main-image{
        width: 100% !important;


    }
}

/* BlogLayout.css */

/* Main blog image styles */
.main-image {
    width: 70%; /* Full width of the container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Cover the area without stretching the image */
}

/* Sub-blog image styles */
.sub-image {
    width: 100%; /* Full width of the sub-blog container */
    height: 200px !important;  /* Fixed height, you can adjust this as needed */
    object-fit: cover; /* Cover the area without stretching the image */
    margin-bottom: 15px; /* Optional: adds some space below the image */
}
