.buycart-card {
  margin: 1rem;
  width: 750px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;
  border: 0px;
}
.cart-header {
  display: flex;
  justify-content: space-around;
}
.cart-price {
  font-weight: bold;
  font-size: 26px;
}
.cartinput {
  width: 50px !important;
}
.cart-subtitle {
  color: rgb(112, 112, 112);
  font-size: 14px;
  font-weight: 800;
}
.cart-title {
  font-size: 26px;
  font-weight: bold;
}
.item-count {
  font-weight: bold;
  font-size: 1.2rem;
  margin: 1rem;
}
.cart-6-days {
  font-weight: bold;
  color: #42c86b;
}
.cart-footer {
  display: flex;
  justify-content: space-between;
}
.cart-main-footer {
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  font-size: large;
  font-weight: bold;
}
.items-and-summary {
  display: flex;
  justify-content: space-between;
}
.buy-summary-cart {
  margin-top: 1rem;

  border: 0px;
  width: 40%;
  height: 32rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;
}
.place-order-btn {
  width: 100%;
  font-weight: bold;
  /* color: black; */
  font-size: 17px;
  background-color: #42c86b;
  border-color: #42c86b;
}
.cart-pro-img {
  width: 13rem;
}
.empty-cart-image {
  width: 13rem;
}
.countHandler input {
  width: 40px;
  text-align: center;
  font-weight: bolder;
}

@media only screen and (max-width: 500px) {
  .cart-footer {
    flex-direction: column;
  }
  .items-and-summary {
    flex-direction: column;

    margin-top: 1rem;
  }
  .buy-summary-cart {
    width: 100%;
    height: 32rem;
  }
  .buycart-card {
    margin: 0px !important;
    width: 100%;
    line-height: 0.6;
  }
  .cart-pro-img {
    width: 140px;
    height: 166px;
    display: block;
    margin: auto;
  }
  .cart-header {
    text-align: center;
    flex-direction: column;
    justify-content: space-around;
  }
}
