@media only screen and (max-width: 1500px) {
  .treanding_cards {
    width: 17rem !important;
    height: 55vh;
  }
}
@media only screen and (max-width: 1200px) {
  .treanding_cards {
    width: 11.8rem !important;
    height: 45vh;
  }
  .Price_card {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .treanding_cards {
    height: 27vh !important;
    width: 10rem !important;
  }
}
@media only screen and (max-width: 400px) {
  .treanding_cards {
    height: 30vh !important;
  }
  .trend_img img {
    height: 90px !important;
    width: 90px !important;
  }
}
@media only screen and (max-width: 400px) {
  .lessthen .card {
    height: 30vh !important;
    width: 9rem !important;
  }
  .trend_img img {
    height: 80px !important;
    width: 80px !important;
  }
}
.lessthen .card {
  height: 370px;
}
