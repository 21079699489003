.cardproduct {
    background-color: #fff;
    border-radius: 0.15em;
    text-decoration: none;
    border: 1px solid white;
    color: #222;
    position: relative;
    font-size: .85rem;
    font-weight: 400;
    text-align: center;
    padding: 20px 10px;
    height: 100%;
    width: 100%;
    cursor: pointer;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

}

.cardproduct:hover {
    box-shadow: 0 16px 11px rgba(33, 33, 33, .1);
    border: 1px solid #0eec0e;
}


.seriesBtn {
    font-weight: bold;
    padding: 5px;
    height: 50px;
    background-color: transparent;
    color: #000;
    border: 2px solid #000;
    border-radius: 0px;
    /* width: 390px; */
    width: 25%;
    
    margin-top: 10px
}
.series-dropdown-list{
    width: 25%;
}

.series-list {
    /* margin-left: 640px; */
  text-align: center;
}

@media screen and (max-width: 500px) {

    .series-list {
        margin-left: 0px;
    }
    .seriesBtn {
        width: 90%;

    }
    .series-dropdown-list{
        width: 100%;
    }
}