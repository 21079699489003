 /* .trndmain{
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth; 
} */

/*.trndtxt{
  text-align: center;
  font-weight: 500;
  text-decoration: overline 2px #55efc4 ;
}
.trendingCard{
 width: 180px;
 height: 250px;
 margin: 20px;
 padding: 10px;
 cursor: pointer;
 background-color: #ffffff;
 border-radius: 15px;
 box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.btntrend{
  margin-top: 10px;
  font-size: 10px;
  background-color: #55efc4;
  color: black;
  border-radius: 20px;
  border: none;
}
.cardbg{
  background: linear-gradient(90deg, rgba(85,239,196,1) 0%, rgba(129,236,236,1) 100%);
  margin-top: 20px;
  color: black;
  width: 85%;
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
  padding: 25px 26px 25px 26px;
}
.cardimg{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.freetxt{
  margin-left: 20px;
  font-weight: 500;
  font-size: 20px;
  margin-top: 10px;
  cursor: pointer;
  color: #000000;
}
.freetxt2{
  margin-left: 20px;
  font-weight: 500;
  font-size: 15px;
  margin-top: 10px;
  cursor: pointer;
  color: #000000;
}
@media screen and (max-width: 924px) {
  .imgship{
      height: 2px;
      width: 2px;
      display: none;
  }
  .cardimg{
      text-align: center;
      display: block;
  }
  .freetxt{
      margin-top: 0;
      margin-left: 0;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
  }
  .freetxt2{
      margin-top: 0;
      margin-left: 0;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
  }
} */

@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

#trending-gadgets .owl-prev,
#trending-gadgets .owl-next {
  position: absolute;
  top: 50%;
  border-radius: 50% !important;
  transform: translateY(-100%);
  width: 50px;
  height: 50px;
  font-size: 20px !important;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  z-index: 1000;
  border:3px solid gray !important;
  background-color: whitesmoke !important;
  color: gray !important;
}



#trending-gadgets .owl-nav .owl-prev{
  left: -10px !important;
}

#trending-gadgets .owl-nav .owl-next{
  right: -10px !important;
}
#trending-gadgets .owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled {
  display: block !important;
}

  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: gray;
    transform: translate3d(0px, -50%, 0px) scale(0.8);
  }
  .owl-carousel .owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .owl-carousel .owl-dots .owl-dot{ 
    display: inline-block;
  }
  .owl-carousel .owl-dots .owl-dot span{ 
    /* background: #eaa400; */
    background: #56423D;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;
  } 

  #trending-gadgets .card-img{
    height: 300px;
  }
  .owl-item {
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  #trending-gadgets .rounded-top-left {
    position:absolute;
    display: flex;
    font-size: 1.5rem;
    font-weight: 900;
    padding: 2.3rem; 
    top: -20px;
    left: -20px;
    border-radius: 50%;
    height: 70px; /* Adjust the height of the rounded div */
    width: 70px;
    background-color: #fd5c63; /* Background color of the rounded div */
    text-align: center;
    color: white;
    justify-content: center;
    align-items: center;
  }

  #trending-gadgets .card-body{
    padding: 0px;
  }
  #trending-gadgets .discount{
    margin-bottom: 0px;
    font-size: 1.25rem;
    position: relative;
    font-weight: bolder;
    top: 15px;
    left: 2px;
  }

  #trending-gadgets .discount-off{
    font-size: 1rem;
    position: relative;
    right: 0px;
    top: 10px;
  }

  #trending-gadgets .rounded-top-right {
    position:absolute;
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    text-align: center;
    right: -20px;
    top: -20px;
    border-radius: 50%;
    height: 70px; /* Adjust the height of the rounded div */
    width: 70px;
    background-color: #fd5c63; /* Background color of the rounded div */
    justify-content: center;
    align-items: center;
    color: white;
  }

  #trending-gadgets .brand-card:hover{
    cursor: pointer;
  }

  .overlay {
    position: absolute;
    border-radius: 8px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black overlay with transparency */
    opacity: 0; /* Initially invisible */
    transition: opacity 0.3s ease; /* Smooth transition effect */
    z-index: 1; /* Ensure the overlay stays behind other elements */
  }

  
  .brand-card:hover .overlay {
    opacity: 1; /* Show the overlay on hover */
  }
  .view-all {
    position: absolute;
    color: white;
    font-weight: bold;
    text-decoration: underline;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0; /* Initially hide the view-all div */
    transition: opacity 0.3s ease; /* Add transition effect for smooth visibility */
    z-index: 1; /* Ensure the "View All" text is above the overlay */
  }
  
  .brand-card:hover .view-all {
    opacity: 1; /* Show the view-all div when the card is hovered */
  }
  
  #trending-gadgets .brand-card{
    position: relative;
    margin: 20px ;
    border-radius:5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  /* Parent container */
  #trending-gadgets .parent-container {
    border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
    background-color: #378b29;
   display: flex;
  height: 60px; /* Set the height of the parent container */
  color: white;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.owl-carousel {
  touch-action: pan-y;
  -webkit-overflow-scrolling: smooth;
}


 .mrp-section{
  margin-left: 5px;
 }
/* .price-section */


  #trending-gadgets .mrp{
    text-decoration: line-through;
  }
  #trending-gadgets .price{
    font-size: 1.5rem;
    font-weight: bold;
  }

  #trending-gadgets .trending-gad-title{
    padding: 5px;
    font-size: 1.1rem;
    font-weight: bolder;
    font-family:'Montserrat', sans-serif;
    height: 70px;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden ;
  }
  #trending-gadgets .owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled{
    display: block !important;
  }

  .owl-carousel .owl-dots .owl-carousel .owl-nav{
    display: block;
  }

 

  @media screen and (min-width: 900px) and (max-width: 1120px) {
    /* Add your responsive styles here */
    .buy {
        width: 80%;
        left: 60%;
    }
  }
  @media screen and (min-width: 600px)  {
    /* Add your responsive styles here */
 
    #trending-gadgets  .owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled{
      display: block !important;
    }

    #trending-gadgets .owl-carousel .owl-dots .owl-carousel .owl-nav{
      display: block;
    }
  }

  @media screen and (min-width: 700px) and (max-width:800px) {
    .buy{
        width:90% ;
        left: 55%;
    }
    .img-slider img{
      height: 350px;
    }
  }

  @media screen and (min-width: 590px) and (max-width:700px) {
    .buy{
        width:60% ;
        left: 70%;
    }
    .img-slider img{
      height: 450px;
    }
  }

  @media screen and (min-width: 100px) and (max-width:500px) {
    #trending-gadgets .card-img{
      height: 400px;
    }
  }

  @media screen and (min-width:481px) and (max-width:768px){
    #trending-gadgets .card-img{
      height: 250px;
    }
    #trending-gadgets .brand-card{
      margin: 8px; 
    }
    #trending-gadgets .rounded-top-left{
      height: 60px;
      width: 60px;
      top: -8px;
      left: -9px;
      font-size: 1.25rem;
    }
    #trending-gadgets .rounded-top-right{
      height: 60px;
      width: 60px;
      top: -8px;
      right: -9px;
    }
    #trending-gadgets .discount{
      font-size: 1rem;
      top: 13px;
    }
    #trending-gadgets .discount-off{
      font-size: 0.8rem;
      right: 0px;
      top: 8px;
    }
    #trending-gadgets .price{
      font-size: 1.1rem;
    }
    #trending-gadgets .mrp{
      font-size: 0.9rem;
    }
    #trending-gadgets .trending-gad-title{
      font-size: 1.1rem;
      height: 50px;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* Number of lines to show */
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    #trending-gadgets .parent-container {
      height: 55px;
    }
  }
  @media screen and (min-width:601px) and (max-width:768px) {
    #trending-gadgets .card-img{
      height: 300px;
    }
  }
 

  @media screen and (max-width:480px){
  
    
    #trending-gadgets .card-img{
      height: 180px;
    }
    #trending-gadgets .brand-card{
      margin: 5px;   
    }

    .owl-carousel .owl-nav.disabled{
      display: none !important;
    }

    .owl-carousel .owl-dots .owl-carousel .owl-nav{
      display: none !important;
    }
    #trending-gadgets .rounded-top-left{
      height: 50px;
      width: 50px;
      top: -6px;
      left: -6px;
      font-size: 1rem;
      padding: 1.6rem;
    }
    #trending-gadgets .rounded-top-right{
      height: 50px;
      width: 50px;
      top: -6px;
      right: -6px;
    }
    #trending-gadgets .discount{
      font-size: 0.9rem;
      top: 13px;
    }
    #trending-gadgets .discount-off{
      font-size: 0.7rem;
      right: 0px;
      top: 8px;
    }
    #trending-gadgets .price{
      font-size: 1rem;
    }
    #trending-gadgets .mrp{
      font-size: 0.8rem;
    }
    #trending-gadgets .trending-gad-title{
      font-size: 0.9rem;
      height: 40px;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* Number of lines to show */
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    #trending-gadgets .parent-container {
      height: 50px;
    }
  }


  @media screen and (min-width:401px) and (max-width:480px) {
    #trending-gadgets .card-img{
      height: 230px;
    }
  }



  


