.loginmain {
  /* background-image: url('../../Images/edited/jpeg.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
img {
  vertical-align: baseline;
}

.card-content form {
  width: 100%; /* Make the form take up the full width */
}

.card-content .enter-otp,
.card-content .not-received-otp,
.card-content .btnlogin,
.card-content .inputStyle {
  width: 100%; /* Make individual elements take up full width */
}

.inputStyle {
  width: 100%; /* Ensure the OTP input fields take up full width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-top: 0.6rem;
}

.logincard {
  margin-top: 30px;
  height: 100%;
  background-color: #ffffff;
  width: 80%;
}

.logindiv1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagelogin {
  height: 80%;
  width: 70%;
}

.logindiv2 {
  background: linear-gradient(
    90deg,
    rgb(215, 244, 206) 37%,
    rgb(183, 253, 178) 70%
  );
  height: 100%;
  color: #000000;
  padding: 30px;
}

.txtbx {
  background-color: transparent;
  outline: none;
  border-radius: 0px !important;
  color: #000000;
  margin-top: 5px;
  box-shadow: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid sliver;
  padding: 3px 0px;
  transition: border-color 0.3s ease;
}
.txtbx:focus {
  border-bottom: 2px solid blue;
  outline: none !important;
  box-shadow: none;
}

.inputStyle {
  width: 50px !important;
  height: 50px;
  border-radius: 7px;
  border: 0px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  background: #dddddd;
  font-size: 20px;
}

.btnlogin {
  background-color: #378b29;
  background-image: linear-gradient(270deg, #74d680 0%, #378b29 74%);
  color: #ffffff;
  width: 100%;
  border: none;
  margin-top: 1rem;
}

.btnlogin:hover {
  color: #ffffff;
}
.existing-user {
  background-color: white;
  color: #2874f0;
  width: 100%;
  border: none;
  margin-top: 1rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.existing-user:hover {
  background-color: white;
  cursor: pointer;
  color: #2874f0;
}
.loginform {
  display: flex;
  flex-direction: column;
}
.form-inputs {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.getstarted {
  font-size: 1.5rem;
}

.logCard {
  /* padding: 20px; */
  display: flex;
  flex-direction: row;
  border: none;
  background-color: #378b29;
  background-image: linear-gradient(180deg, #74d680 0%, #378b29 74%);
  border-radius: 20px;
  min-height: 450px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.input-card {
  background-color: white;
  border-radius: 20px;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 0px;
  border: none;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-content {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.otp-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.no-align-center {
  align-self: center; /* Aligns the item to the start */
  width: 80%; /* Ensure the container takes full width */
}

.enter-otp {
  margin-top: 10px;

  margin-bottom: 15px;
  font-family: "Roboto,Arial,sans-serif";
  color: #212121;
  font-size: 20px;
}
.change {
  font-size: 1rem;
}
.imglogcard {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  background-color: #cfcfcf;
  border: 5px solid #ffffff;
}

.not-recieved-otp {
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.txtlogin {
  text-align: center;

  margin-bottom: 1rem;
  font-weight: bold;
  color: linear-gradient(
    90deg,
    rgba(44, 165, 35, 1) 0%,
    rgba(36, 93, 17, 1) 64%
  );
}
.terms {
  font-size: 0.8rem;
  margin-top: 10px;
  margin-bottom: 0px !important;
}
.terms-link {
  color: #2874f0;
  font-size: 0.8rem;
}

.otp-send {
  margin-bottom: 0.8rem;
  margin-left: 1.5rem;
}
.otp-login {
  margin-bottom: 0.8rem;
}

.mobcard {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.plzOtp {
  font-size: 1.1rem;
}

@media screen and (max-width: 600px) {
  .no-align-center {
    width: 100%;
  }
  .logCard {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .mobcard {
    width: 100%;
    height: 50%;
  }
  .input-card {
    border-top-left-radius: 60px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    width: 100%;
    height: 60%;
    padding: 1rem;
  }

  .card-content {
    width: 100%;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .mobcard {
    width: 45%;
  }
  .input-card {
    width: 55%;
  }
  .card-content {
    width: 80%;
  }
  .plzOtp {
    font-size: 1rem;
  }
}
