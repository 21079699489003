.Worktxt {
    text-align: center;
    font-weight: 600;
    text-decoration: overline 2px #55efc4;
}

.cardwork {
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    height: 350px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
    border-bottom: 2px solid #ff6b58;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.cardwork4:hover,
.cardwork3:hover,
.cardwork2:hover,
.cardwork:hover {
    transform: translateY(-8px);
    border-radius: 0%;
}

.txtwork {
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    margin-top: 20px;
}

.txtwork2 {
    font-size: 13px;
}

.workbtn {
    background-color: transparent;
    border: 2px solid #ff6b58;
    color: #ff6b58;
    font-size: 12px;
    padding: 10px 20px 10px 20px;
}

.workcircle1 {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid #878787;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconworkcircle {
    height: 85px;
    width: 85px;
    background-color: #ff6b58;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardwork:hover .workbtn {
    background-color: #ff6b58;
    color: #ffffff;
    border: none;
    padding: 10px 20px 10px 20px;
}

.cardwork2 {
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    height: 350px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
    border-bottom: 2px solid #148ada;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.workcircle2 {
    /* height: 100px;
    width: 100px;
    border-radius: 50%; */
    background-color: #ffffff;
    border: 1px solid #878787;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 50%;
}

.iconworkcircle2 {
    height: 85px;
    width: 85px;
    background-color: #148ada;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.workbtn2 {
    background-color: transparent;
    border: 2px solid #148ada;
    color: #148ada;
    font-size: 12px;
    padding: 10px 20px 10px 20px;
}

.cardwork2:hover .workbtn2 {
    background-color: #148ada;
    color: #ffffff;
    border: none;
    padding: 10px 20px 10px 20px;
}


.cardwork3 {
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    height: 350px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
    border-bottom: 2px solid #40a737;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.workcircle3 {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid #40a737;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconworkcircle3 {
    height: 85px;
    width: 85px;
    background-color: #40a737;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.workbtn3 {
    background-color: transparent;
    border: 2px solid #40a737;
    color: #40a737;
    font-size: 12px;
    padding: 10px 20px 10px 20px;
}

.cardwork3:hover .workbtn3 {
    background-color: #40a737;
    color: #ffffff;
    border: none;
    padding: 10px 20px 10px 20px;
}


.cardwork4 {
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    height: 350px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
    border-bottom: 2px solid #c260fb;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.workcircle4 {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid #c260fb;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconworkcircle4 {
    height: 85px;
    width: 85px;
    background-color: #c260fb;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.workbtn4 {
    background-color: transparent;
    border: 2px solid #c260fb;
    color: #c260fb;
    font-size: 12px;
    padding: 10px 20px 10px 20px;
}

.cardwork4:hover .workbtn4 {
    background-color: #c260fb;
    color: #ffffff;
    border: none;
    padding: 10px 20px 10px 20px;
}

@media screen and (max-width: 992px) {
    .txtwork2 {
        display: none;
    }
    .txtwork {
        font-size: 18px;
        font-weight: 500;
        color: #000000;
        margin-top: 20px;
    }
    .cardwork {
        padding: 15px;
    }
    .cardwork2 {
        padding: 15px;
    }
    .cardwork3 {
        padding: 15px;
    }
    .cardwork4 {
        padding: 15px;
    }
}
@media screen and (max-width: 767px) {
    .txtwork2 {
        display: block;
    }
    .txtwork {
        font-size: 20px;
        font-weight: 500;
        color: #000000;
        margin-top: 20px;
    }
    .cardwork {
        padding: 25px;
    }
    .cardwork2 {
        padding: 25px;
    }
    .cardwork3 {
        padding: 25px;
    }
    .cardwork4 {
        padding: 25px;
    }
}

@media screen and (max-width: 768px) {
    .how-we-work-responsive-tab{
        flex-direction: column !important;
        >.how-we-work-responsive-tab-item{
            width: 100%;
        }
        
    }
    .txtwork2{
        display: initial !important;
    }
}