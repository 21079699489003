.cart-nav {
  display: flex;
  flex-direction: row-reverse;
  padding: 0.5rem;
  background-color: rgb(64, 167, 55);
  font-size: 30px;
}
.filter-card .accordion-item {
  border: 0px;
  border-bottom: 1px solid #dee2e6;
}

.m-5 {
  margin: 1rem !important;
}
.buy-card {
  width: 22vw;
  height: fit-content;
}
.sellprice {
  text-decoration: line-through;
  text-decoration-color: red;
}
.buy-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Adding transition for both transform and box-shadow */
}

.buy-card:hover {
  transform: translateY(-5px); /* Move the card up slightly on hover */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Shadow effect on card hover */
}

.btn-hover:hover {
  transform: scale(1.05); /* Scale up on button hover */
  transition: transform 0.3s ease; /* Transition effect for scaling */
}

.filter-card {
  width: 500px;
}
@media only screen and (max-width: 500px) {
  .producy-buy-show {
    flex-direction: column;
  }
  .card-wrapper {
    flex-direction: column;
  }
  .product-buy-show-body {
    margin: 0px !important;
    width: 100% !important;
  }
  .right-card .m-5 {
    margin: 1rem !important;
  }
  .fixed-filter-card {
    position: fixed !important;
    left: 0; /* Adjust left position as needed */
    bottom: 0 !important;
    top: unset !important;
    width: 100%;

    z-index: 1000; /* Ensure it appears above other elements */
  }
  .buycart {
    width: unset !important;
  }
  .filter-card .m-5 {
    margin: 1rem !important;
  }
  .filter-card {
    width: 100% !important;
  }
  .buy-card {
    width: 100% !important;
  }
  .buyProImg {
    justify-content: center;
  }
  .filterpart2 {
    display: none;
  }
  .filterpart2show {
    display: block;
  }
  .mobilefilter {
    display: none;
  }
  .mobilefilterdesk {
    display: block !important;
  }
}

.mobilefilterdesk {
  display: none;
}
.tags {
  font-size: 12px;
}
.buydetailstags {
  font-size: 12px;
}

.filter-card .accordion-button::after {
  background-image: url(../../Images/Downloads/plus-104.png);
}
.filter-card .accordion-button:not(.collapsed)::after {
  background-image: url(../../Images/Downloads/Dash_icon.svg.png);
}
.toast {
  max-width: 100px !important;
}
.buycart {
  width: 1200px;
}
.form-check-input {
  width: 1.1em;
  height: 1.1em;
}
.fixed-filter-card {
  position: sticky;
  left: 0; /* Adjust left position as needed */
  top: 70px;
  width: 100%;
  overflow-y: auto; /* Add vertical scrollbar if content overflows */
  z-index: 1000; /* Ensure it appears above other elements */
}
.filter-card .accordion-button {
  padding: 1.25rem 0.25rem;
}
.filter-card .accordion-body {
  padding: 0px 0.25rem;
}
.filteritem div {
  margin: 10px 0px;
}
.filter-card .accordion-button:focus {
  border: none;
  box-shadow: none;
}
.filter-card .accordion-button:not(.collapsed) {
  color: black;
  background-color: transparent;
}

.filteritem .form-check-input {
  width: 1.3em;
  height: 1.3em;
}

.filteritem .form-check-input:checked {
  background-color: #5abd00;
  border-color: #0d6efd;
}

.filteritem .form-check-label {
  margin-left: 16px;
  font-size: 18px;
}
.filteritem {
  max-height: 200px;
  overflow: auto;
}
