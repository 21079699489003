/* AllNews.css */

/* Base styles */
.news-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.linenews {
  height: 2px;
  width: 80%;
  background:black;
  > .line_inner_news {
    position: absolute;
    height: 5px;
    width: 10%;
    background: black;
    left: 0%;
    top: 50%;
    transform: translateY(-50%);
  }
}


.news-items-container {
  display: flex;
  flex-direction: column;
}
.news-title {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
  transition: color 0.3s ease, text-shadow 0.3s ease; /* Smooth transitions for color and text-shadow */
  cursor: pointer; /* Change cursor to indicate interactivity */
}
.three h1 {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
}
.three h1:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #111;
}
.three h1:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 255px;
  background-color: #333;
}

.news-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  transition: transform 0.3s ease;
}

.news-item.left {
  flex-direction: row;
}

.news-item.right {
  flex-direction: row-reverse;
}

.news-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.news-image {
  width: 40%;
  max-width: 200px;
  margin: 10px;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.news-image:hover {
  transform: scale(1.1);
}

.news-item-content {
  /* width: 50%; */
  padding: 20px;
  text-align: left;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}



.news-summary {
  font-size: 1em;
  color: #555;
  margin-bottom: 10px;
  max-height: 80px;
  overflow: auto;
}

.news-item a {
  text-decoration: none;
  color: #007bff;
  transition: color 0.2s ease;
}

.news-item a:hover {
  color: #0056b3;
}

/* AllNews.css */

/* ... (existing styles) ... */

/* Mobile styles */
@media only screen and (max-width: 600px) {
  .news-item {
    flex-direction: column; /* Stack the image and content vertically */
    align-items: center; /* Center align the items */
  }

  .news-item.left,
  .news-item.right {
    flex-direction: column; /* Override any left or right specific styles for mobile */
  }

  .news-image {
    width: 100%; /* Full width image on mobile */
    max-width: none; /* Remove max-width restriction */
    margin: 0 0 10px; /* Reset margin for mobile, add space below the image */
  }

  .news-item-content {
    width: 100%; /* Full width content area */
    padding: 15px; /* Adjust padding for mobile */
    text-align: center; /* Center the text for mobile */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow for separation */
  }

  .news-title {
    font-size: 1.3em; /* Adjust font size for mobile */
  }

  .news-summary {
    /* Adjust styles as necessary for mobile */
    font-size: 1em;
    margin-bottom: 10px;
  }

  /* Adjust link styles for mobile if needed */
  .news-item a {
    /* ... */
  }
}


