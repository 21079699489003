@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

* {
  font-family: Poppins;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color_Blue: #ffffff;
  --color_Dark1: #032276;
  --color_Dark2: #f8d0d0;
  --color_Light1: #000000;
  --color_Light2: #000000;
}

.adminsidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  background: linear-gradient(
    180deg,
    rgb(228, 251, 220) 37%,
    rgb(183, 253, 178) 70%
  );
  transition: all 0.5s ease;
  z-index: 100;
}

.adminsidebar.close {
  width: 78px;
}

a {
  text-decoration: none;
}

.adminlogo-box {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--color_Light1);
  transition: all 0.5s ease;
}

.adminlogo-box:hover {
  color: var(--color_Blue);
}

.adminlogo-box i {
  font-size: 30px;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  transition: all 0.5s ease;
}

.adminsidebar.close .adminlogo-box i {
  transform: rotate(360deg);
}

.adminlogo-name {
  font-size: 22px;
  font-weight: 600;
}

.adminsidebar-list {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}

.adminsidebar-list::-webkit-scrollbar {
  display: none;
}

.adminsidebar-list li {
  transition: all 0.3s ease;
}

.adminsidebar-list li:hover {
  background-color: var(--color_Dark2);
}

.adminsidebar-list li .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease;
  cursor: pointer;
}

.adminsidebar-list li.active .title {
  background-color: var(--color_Blue);
}

.adminsidebar-list li.active .bxs-chevron-down {
  transition: all 0.5s ease;
  transform: rotate(180deg);
}

.adminsidebar-list li .title .link {
  display: flex;
  align-items: center;
}

.adminsidebar-list li .title i {
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: var(--color_Light1);
  font-size: 20px;
}

.adminsidebar-list li .title .name {
  font-size: 18px;
  font-weight: 400;
  color: var(--color_Light1);
}

.adminsidebar-list li .submenu {
  width: 0;
  height: 0;
  opacity: 0;
  transition: all 0.5s ease;
}

.adminsidebar-list li.dropdown.active .submenu {
  width: unset;
  height: unset;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 6px 6px 14px 80px;
  background-color: var(--color_Dark2);
}

.submenu .link {
  color: var(--color_Light2);
  font-size: 15px;
  padding: 5px 0;
  transition: all 0.5s ease;
}

.submenu .link:hover {
  color: #fff;
}

.submenu-title {
  display: none;
}

.adminsidebar.close .adminlogo-name,
.adminsidebar.close .title .name,
.adminsidebar.close .title .bxs-chevron-down {
  display: none;
}

.adminsidebar.close .adminsidebar-list {
  overflow: visible;
}

.adminsidebar.close .adminsidebar-list li {
  position: relative;
}

.adminsidebar.close .adminsidebar-list li .submenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  height: max-content;
  width: max-content;
  opacity: 0;
  transition: all 0.5s ease;
  pointer-events: none;
}

.adminsidebar.close .adminsidebar-list li:hover .submenu {
  opacity: 1;
  top: 0;
  pointer-events: initial;
  background-color: var(--color_Dark2);
}

.adminsidebar.close .submenu-title {
  display: block;
  font-style: 18px;
  color: var(--color_Light1);
}

.adminhome {
  position: relative;
  background-color: #ffffff;
  left: 260px;
  width: calc(100% - 260px);
  height: 100%;
  transition: all 0.5s ease;
}

.adminsidebar.close ~ .adminhome {
  left: 78px;
  width: 100%;
}

@media (min-width: 768px) {
  .adminsidebar.close ~ .adminhome {
    width: calc(100% - 78px);
  }
}

.adminhome .toggle-adminsidebar {
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.adminhome .toggle-adminsidebar i {
  font-size: 35px;
  color: #4b6584;
  margin-left: 15px;
}

.adminhome .toggle-adminsidebar .text {
  font-size: 25px;
  color: #4b6584;
  font-weight: 600;
}

.profile {
  justify-content: right;
  height: 30px;
  width: 50px;
}

.topdash {
  background: linear-gradient(
    90deg,
    rgb(228, 251, 220) 37%,
    rgb(183, 253, 178) 70%
  );
  width: 100%;
  padding: 20px;
  height: 80px;
  display: flex;
}

.topdash2 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
