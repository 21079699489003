.dealercard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  border-radius: 10px;

  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.cardbgservice2 {
  background-color: #e7ebee;
  height: 10%;
  color: black;
  width: 100%;
  padding: 40px;
}

.txtdealer {
  margin-top: -10%;
  text-align: center;
}

.btndealer {
  background-color: #40a737;
  border: none;
  color: #ffffff;
  font-size: 12px;
  padding: 10px 20px 10px 20px;
}

.btndealer:hover {
  background-color: #40a737;
  border: none;
  color: #ffffff;
}

.SellBox {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(100deg, #05b74f, #08c18d); */
  background: linear-gradient(
    90deg,
    rgba(185, 56, 116, 1) 0%,
    rgba(239, 91, 60, 1) 64%
  );
  border-radius: 100%;
  margin-top: 15px;
}
.flchart .SellBox {
  background: #40a737;
}
.Selline {
  height: 71px;
  width: 5px;
  background-color: #44ce30;
  margin-top: 20px;
}

.lottieAnim {
  width: 80%;
  height: 80%;
  margin-top: 20px;

  visibility: visible;
}

.lineArrow1 {
  width: 4px;
  height: 30px;
  /* background-color: #40a737; */
  background-color: rgba(239, 91, 60, 1);
  margin-left: 37px;
}
@media only screen and (max-width: 500px) {
  .flchart .line {
    margin: 0px;
    margin-top: 0rem;
    margin-bottom: 5rem;
    font-size: 19px;
  }
  .flchart h5 {
    font-size: 15px !important;
  }
  .mbpart1 {
    margin-top: -35px !important;
  }
  .mbpart2 {
    margin-top: -15px !important;
  }
  .mbpart3 {
    margin-top: -10px !important;
  }
  .mbpart4 {
    margin-top: -20px !important;
  }
  .notes {
    font-size: 15px !important;
    margin: 0px !important;
    padding: 0px !important;
  }
  .BgNavbar .menubar {
    visibility: visible !important;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1180px) {
  .BgNavbar .menubar {
    visibility: visible !important;
  }
}

@media only screen and (min-width: 1181px) {
  .BgNavbar .menubar {
    visibility: hidden;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .BgNavbar .menubar {
    visibility: visible !important;
  }
}

@media only screen and (min-width: 1367px) {
  .BgNavbar .menubar {
    visibility: hidden;
  }
}
@media only screen and (min-width: 1024px) and (min-height: 1366px) {
  .BgNavbar .menubar {
    visibility: hidden !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (min-height: 1024px) {
  .BgNavbar .menubar {
    visibility: visible !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (min-height: 1024px) {
  .BgNavbar .menubar {
    visibility: visible !important;
  }
}

@media only screen and (min-width: 1025px) {
  .BgNavbar .menubar {
    visibility: hidden !important;
  }
}


.BgNavbar .menubar {
  visibility: hidden;
}
.triangle_down1 {
  position: relative;
  top: -17px;
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  /* border-right: 0.2em solid #40a737; */
  border-right: 0.2em solid rgba(239, 91, 60, 1);
  /* border-top: 0.2em solid #40a737; */
  border-top: 0.2em solid rgba(239, 91, 60, 1);
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 2em;
}
.flchart .lineArrow1,
.flchart .lineArrow2,
.flchart .lineArrow3,
.flchart .lineArrow4,
.flchart .lineArrow5 {
  background-color: #40a737 !important;
}
.flchart .triangle_down1,
.flchart .triangle_down2,
.flchart .triangle_down3,
.flchart .triangle_down4 {
  border-right: 0.2em solid #40a737 !important;
  /* border-right: 0.2em solid rgba(239, 91, 60, 1); */
  border-top: 0.2em solid #40a737 !important;
}

.lineArrow2 {
  width: 4px;
  height: 30px;
  /* background-color: #40a737; */
  background-color: rgba(239, 91, 60, 1);
  margin-left: 37px;
}

.triangle_down2 {
  position: relative;
  top: -17px;
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  /* border-right: 0.2em solid #40a737; */
  border-right: 0.2em solid rgba(239, 91, 60, 1);
  /* border-top: 0.2em solid #40a737; */
  border-top: 0.2em solid rgba(239, 91, 60, 1);
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 2em;
}

.lineArrow3 {
  width: 4px;
  height: 30px;
  /* background-color: #40a737; */
  background-color: rgba(239, 91, 60, 1);
  margin-left: 37px;
}

.triangle_down3 {
  position: relative;
  top: -17px;
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  /* border-right: 0.2em solid #40a737; */
  border-right: 0.2em solid rgba(239, 91, 60, 1);
  /* border-top: 0.2em solid #40a737; */
  border-top: 0.2em solid rgba(239, 91, 60, 1);
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 2em;
}

.lineArrow4 {
  width: 4px;
  height: 30px;
  /* background-color: #40a737; */
  background-color: rgba(239, 91, 60, 1);
  margin-left: 37px;
}

.triangle_down4 {
  position: relative;
  top: -17px;
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  /* border-right: 0.2em solid #40a737; */
  border-right: 0.2em solid rgba(239, 91, 60, 1);
  /* border-top: 0.2em solid #40a737; */
  border-top: 0.2em solid rgba(239, 91, 60, 1);
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 2em;
}

@media screen and (max-width: 912px) {
  .txtdealer {
    margin-top: -10%;
    text-align: center;
    font-size: 18px;
  }

  .lineArrow1 {
    height: 30px;
    /* background-color: #40a737; */
    background-color: rgba(239, 91, 60, 1);
  }

  .lineArrow2 {
    height: 80px;
    /* background-color: #40a737; */
    background-color: rgba(239, 91, 60, 1);
  }

  .lineArrow3 {
    height: 40px;
    /* background-color: #40a737; */
    background-color: rgba(239, 91, 60, 1);
  }

  .lineArrow4 {
    height: 80px;
    /* background-color: #40a737; */
    background-color: rgba(239, 91, 60, 1);
  }
}

.arrow {
  margin: 25px;
  @include arrow(12px, 2px, 2px, 20px);
  @include arrowColor(red);
}

.whycardNew {
  margin-top: 30px;
}

.whycardNew:hover .imgchooseNew {
  transform: scale(1.1);
}

.imgchooseNew {
  height: 40px;
  width: 40px;
  transition: 1s;
}

.imgwhyNew {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #f1c40f 0%, #f39c12 64%);
}

.txtchooseNew {
  font-weight: 600;
  font-size: 20px;
  margin-top: 10px;
  margin-left: 20px;
}

@media screen and (max-width: 600px) {
  .Selline {
    height: 150px;
    width: 5px;
  }

  .lottieAnim {
    visibility: hidden;
  }

  .imgwhyNew {
    width: 60px;
    height: 40px;
  }

  .txtchooseNew {
    font-size: 13px;
  }

  .imgchooseNew {
    height: 20px;
    width: 20px;
    transition: 1s;
  }
}
