.overlaycard {
  position: absolute;
  border-radius: 8px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.brandcard:hover .overlaycard {
  opacity: 1;
}
.view {
  position: absolute;
  color: white;
  font-weight: bold;
  text-decoration: underline;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.brandcard:hover .view {
  opacity: 1;
}

.imagezoom img {
  max-width: 100%;
  cursor: pointer;
  max-height: 70vh;
  border-radius: 1rem;
}

@media (max-width: 480px) {
  .imagezoom img {
    max-height: 60vh;
  }
}

.swiper-pagination {
  bottom: 1rem !important;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  left: unset !important;
  transform: unset !important;
}

.swiper-pagination-bullet-active {
  background: rgb(6, 155, 170);
}

.grayscale-img {
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-height: 80vh;
  overflow-y: scroll;
}
