.brand-container {
    border-radius: 2px solid black;
    padding: 10px;

}

.title-brand {
    padding: 15px;
    justify-content: center;
    display: flex;
}

.card-1 {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 0.15em;
    text-decoration: none;
    color: #222;
    position: relative;
    font-size: .85rem;
    font-weight: 400;
    text-align: center;
    padding: 20px 10px;
    height: 100%;
    width: 100%;
    cursor: pointer;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-1:hover {
    box-shadow: 0 16px 11px rgba(33, 33, 33, .1);
    border: 1px solid #0eec0e;
}

.product-container {
    box-shadow: 0 16px 11px rgba(33, 33, 33, .1);
    margin: 1rem;
    margin-left: 5rem;
    margin-right: 1rem;
}

.product-image {
    box-shadow: 0 16px 11px rgba(33, 33, 33, .1);
    margin: 5rem;
    width: 18rem;
}

.product-content {
    display: flex;
    position: relative;
    left: 6rem;
}

.product-name {
    position: relative;
    left: 4.4rem;
    top: 3rem;
}

.variant {
    position: relative;
    top: 6rem;
}

.variant-btn {
    /* margin-top: 1rem;
    margin-right: 1rem; */
    border: 1px solid black;
    border-color: #fff;
}

.variant-btn:hover {
    /* background-color: #0eec0e; */
    border: 1px solid #0eec0e;
}

.hover-effect:hover {
    cursor: pointer;
    border: 1px solid #0eec0e;
}

.img-icon {
    max-width: 100%;
    display: block;
    width: auto;
    height: 50px;
    margin: 0 auto;
    -webkit-transition: all .5s;
    transition: all .5s;
}

.card-text {
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: .2px;
    margin: 13px;
    text-transform: capitalize;
}

.banner {
    background-color: #EFFEF3;
    color: #333;
    padding-top: 15px;
    padding: 15px;
    border-radius: 20px;
    margin: 1rem;
}

.banner h2 {
    font-size: 27px;
}

.sellsearchitem {
    cursor: pointer;
    border-radius: 5px;
    margin-top: 5px;
}

.sellsearch {
    z-index: 100;
    padding: 15px;
    right: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: white;
}

.search-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 2px;
}

.search-input {
    box-shadow: rgba(153, 152, 152, 0.35) 0px 5px 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin-right: 10px;
    width: 308px;
}

.search-icon {
    display: flex;
    align-items: center;
    padding: 5px;
}

@media screen and (max-width: 600px) {
    .banner h2 {
        font-size: 21px;
        margin-top: 16px;
        font-weight: 600;
        margin-bottom: 1rem;

    }

    .search-bar {
        flex-direction: row;
    }

    .search-input {
        width: 100%;
        margin-bottom: 10px;
    }

}

.breadcrumb {
    font-size: 12px;

}

.specific-product-card {

    border: 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.specific-product-image {
    width: 65%;
}

@media screen and (max-width: 600px) {
    .specific-product-image {
        width: 45%;
    }
}