@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
.trndmain{
    width: 100%;
    overflow: hidden;
}


#greviews-slider .owl-prev,
#greviews-slider .owl-next {
  position: absolute;
  top: 50%;
  border-radius: 50% !important;
  transform: translateY(-100%);
  width: 50px;
  height: 50px;
  font-size: 20px !important;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  z-index: 1000;
  border:3px solid gray !important;
  background-color: whitesmoke !important;
  color: gray !important;
}



#greviews-slider .owl-nav .owl-prev{
  left: -10px !important;
}

#greviews-slider .owl-nav .owl-next{
  right: -10px !important;
}

.google-reviews{
    border-radius: 10px;
    background-color: #378b29;
    background-image: linear-gradient(180deg, #74d680 0%,#378b29  74%);
    padding: 3rem;
    margin-bottom: 20px;
}


#greviews-slider .owl-prev{
    left: 0px !important;
}
#greviews-slider .owl-next{
    right: 0px !important;
}

#greviews-slider .item {
    margin-bottom: 0px;
    opacity: 0.9;
    transform: scale3d(0.8, 0.8, 1);
    transition: all 0.3s ease-in-out;
  }
 
  #greviews-slider .comma{
    display: flex;
    justify-content: center;
  }

  #greviews-slider .owl-item.active.center .item {
    opacity: 1;
    transform: scale3d(1.0, 1.0, 1);
  }

  #greviews-slider .comma{
    font-family: "Poppins";
  }

  #greviews-slider .cared-content{
    margin-top: 40px;
}

#greviews-slider .card-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#greviews-slider .review{
    text-align: center;
    font-size: 1rem;
}



@media screen and (min-width: 100px) and (max-width:576px) {
   .google-reviews{
    padding: 1.5rem;
  }
  #greviews-slider  .owl-nav{
    display: none !important;
  }
  #greviews-slider .owl-dots{
    margin-top: 0.8rem;
  }

}