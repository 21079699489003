.whychooseus {
    background-color: #ffffff;
    padding: 40px;
}

.circlewhy {
    height: 200px;
    width: 200px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #55efc4;
    margin-top: 30px;
    cursor: pointer;
    overflow: hidden;

}

.imgchoose {
    height: 40px;
    width: 40px;
    transition: 1s;
}

.whycard {
    margin-top: 30px;
}

.whycard:hover .imgchoose {
    transform: scale(1.1);
}

.txtchoose {
    font-weight: 600;
    font-size: 20px;
    margin-top: 10px;
    margin-left: 20px;

}

.txtchoose2 {
    font-weight: normal;
    font-size: 13px;
    margin-left: 20px;
    font-weight: 600;
}

.imgwhy {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, rgba(185, 56, 116, 1) 0%, rgba(239, 91, 60, 1) 64%);
}

@media screen and (max-width: 924px) {
    .whychooseus {
        background-color: #ffffff;
        padding: 25px;
        margin-top: 50px;
    }

    .imgwhy {
        height: 55px;
        width: 55px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(90deg, rgba(185, 56, 116, 1) 0%, rgba(239, 91, 60, 1) 64%);
        ;
    }

    .txtchoose {
        font-weight: 600;
        font-size: 20px;
        margin-top: 10px;
        margin-left: 20px;
    }

    .txtchoose2 {
        font-weight: 600;
        font-size: 12px;
        margin-left: 20px;
    }

    .imgchoose {
        height: 30px;
        width: 30px;
        transition: 1s;
    }
}