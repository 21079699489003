.adminloginmain{
    display: flex;
    justify-content: center;
    align-items: center;
}
.adminlogincard{
    margin-top: 30px;
    height: 100%;
    width: 80%;
}
.adminlog{
    background: linear-gradient(90deg, rgb(205, 238, 194) 37%, rgb(183, 253, 178) 70%);
    height: 100%;
    color:#000000;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.txtadmin{
    background-color: transparent;
    border: 2px solid #000000;
    color: #000000;
    border-radius: 20px;
    margin-top: 5px;
}
.btnadmin{
    border-radius: 20px;
    background-color: transparent;
    color: #0a0a0a;
    border: 1px solid #000000;
    margin-top: 10px;
}