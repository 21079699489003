.other-form-lable{
    font-size: 14px;
    font-weight: bold;

}
.other-dropdown{
    background-color: white;
    color:black
}
.other-dropdown:hover {
    background-color: white;
    color:black
}