
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Recursive:wght@300..1000&display=swap');


.crowdfavourites .owl-prev,
.crowdfavourites .owl-next {
  position: absolute;
  top: 50%;
  border-radius: 50% !important;
  transform: translateY(-100%);
  width: 50px;
  height: 50px;
  font-size: 20px !important;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  z-index: 1000;
  border:3px solid gray !important;
  background-color: whitesmoke !important;
  color: gray !important;
}

.crowdfavourites .owl-prev {
  left: -70px !important;
}

.crowdfavourites .owl-next {
  right: -70px !important;
}

.crowdfavourites{
    border-radius: 10px;
    background-color: #378b29;
    background-image: linear-gradient(180deg, #74d680 0%,#378b29  74%);
    padding: 40px;
    margin-bottom: 20px;
}



.shadow-effect {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #ECECEC;
    box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
  }
  #shadow-effect p {
    font-family: inherit;
    font-size: 17px;
    line-height: 1.5;
    margin: 0 0 17px 0;
    font-weight: 300;
  }
 
  #products-slider {
    margin-top: 6%;
  }
  .img-slider {
    position: relative;
  }
  .des{
    display: flex;
  }
  .right-arrow{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .buy {
    position: absolute;
    bottom: 10px;
    left: 65%;
    transform: translateX(-50%);
    z-index: 1; 
    width: 70%;
    height: max-content;
    background-color: #ECECEC;
    border-radius: 20px;
    color: black;
    box-shadow: 0px 9px 18px rgba(0,0,0,0.12), 0px 5px 7px rgba(0,0,0,0.5);
  }
  .arrow-icon{
    font-size: 2rem;
    color: #378b29;
  }

  .brand-text{
    text-align: left;
    font-size: 1.2rem;
    font-family: "Work Sans", sans-serif;
    font-weight: 500;
    margin-left: 1.2rem;
    margin-bottom: 0;
  }
  .img-slider img {
    height:400px; 
    width: 100%; 
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  


  .slider-heading{
    font-size: 3rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  #products-slider .item {
    text-align: center;
    margin-bottom: 0px;
    opacity: 0.9;
    transform: scale3d(0.8, 0.8, 1);
    transition: all 0.3s ease-in-out;
  }
  .item:hover{
    cursor: pointer;
  }

  #products-slider .owl-item.active.center .item {
    opacity: 1;
    transform: scale3d(1.0, 1.0, 1);
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: gray;
    transform: translate3d(0px, -50%, 0px) scale(0.8);
  }
  .owl-carousel .owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .owl-carousel .owl-dots .owl-dot{ 
    display: inline-block;
  }
  .owl-carousel .owl-dots .owl-dot span{ 
    /* background: #eaa400; */
    background: #56423D;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;
  } 

  .brand-des{
    font-size: 0.8rem;
    color: #585858;
    margin-left: 1.2rem;
    text-align: left;
    font-family: "Work Sans", sans-serif; 
  }

  @media screen and (min-width: 900px) and (max-width: 1120px) {
    /* Add your responsive styles here */
    .buy {
        width: 80%;
        left: 60%;
    }
  }

  @media screen and (min-width: 700px) and (max-width:800px) {
    .buy{
        width:90% ;
        left: 55%;
    }
    .img-slider img{
      height: 350px;
    }
  }

  @media screen and (min-width: 590px) and (max-width:700px) {
    .buy{
        width:60% ;
        left: 70%;
    }
    .img-slider img{
      height: 450px;
    }
  }

  @media screen and (min-width: 380px) and (max-width:460px) {
    .buy{
        width:90% ;
        left: 55%;
    }
    .img-slider img{
      height: 320px;
    }
    .brand-text{
      font-size: 0.9rem;
    }
    .brand-des{
      font-size: 0.7rem;
    }
    .main-text{
      font-size: 1.1rem;
    }
   .owl-next,.owl-prev{
    width: 45px;
    height: 45px;
   }
    
  }

  @media screen  and (max-width:380px) {
    .buy{
        width:100% ;
        left: 50%;
    }
    .img-slider img{
      height: 300px;
    }
    .brand-text{
      font-size: 0.8rem;
    }
    .brand-des{
      font-size: 0.6rem;
    }
    .main-text{
      font-size: 0.9rem;
    }
    .owl-next,.owl-prev{
      width: 45px;
      height: 45px;
     }
    
  }

