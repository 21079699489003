.step2-container {
    margin-top: 0.5rem;
    box-shadow: rgba(60, 64, 67, .3) 0px 1px 2px 0px, rgba(60, 64, 67, .15) 0px 1px 3px 1px;
    background-color: #fff;
    border-radius: 0.15em;
    text-decoration: none;
    color: #222;
    font-size: 12px;
    border: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
    transition: .3s ease-in-out;
    width: 100%;
    height: 90%;
    text-align: center;
    font-weight: bold;

}

.step2-container-checked {
    margin-top: 0.5rem;
    box-shadow: rgba(60, 64, 67, .3) 0px 1px 2px 0px, rgba(60, 64, 67, .15) 0px 1px 3px 1px;
    background-color: #fff;
    border-radius: 0.15em;
    text-decoration: none;
    color: #222;
    font-size: 12px;
    border: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
    transition: .3s ease-in-out;
    width: 100%;
    height: 90%;
    text-align: center;
    border: 1px solid #e74c3c;
    font-weight: bold;


}

.step2-container:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media only screen and (max-width: 600px) {
    #last-div {
        display: none;
    }
}